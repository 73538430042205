import {GenerateTitleCards} from "../components/layout/handlers/GlobalHandlers";
import ProductDetail from "../components/UI/products/ProductDetail";
import {Fragment} from "react";
import {useSelector} from "react-redux";

const InstallPage = () => {

    /* Get Content and Install Product */
    const products = useSelector(state => state.content.globalProductsInstall);
    const content = useSelector(state => state.content.staticContent);


    const installProducts = products.map((items) => (
        <ProductDetail item={items} monter />
    ));

    return (
        <Fragment>
            <GenerateTitleCards data={content} group='cover' where='install'/>
            <div className='body-card-container'>
                {installProducts}
            </div>
        </Fragment>
    );
}

export default InstallPage;