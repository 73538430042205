import classes from './PanelPage.module.css';
import {Fragment} from "react";
import {Card} from "@material-ui/core";
import ListOrder from "./orders/ListOrder";
import ListMeldinger from "./meldinger/ListMeldinger";
import LoginPanelPage from "./LoginPanelPage";
import {authAction} from "../../store/auth-slice";
import {useDispatch, useSelector} from "react-redux";

const PanelPage = () => {
    /* Setup Hooks */
    const isLoggedIn = useSelector(state => state.auth.isLoggedIn);
    const viewOrder = useSelector(state => state.auth.viewOrder);
    const session = useSelector(state => state.auth.session);
    const API_URL = useSelector(state => state.content.apiURL);

    /* Get Disptach */
    const disptach = useDispatch();

    const viewOrderHandler = () => {
        disptach(authAction.setViewOrder(true));
    };

    const viewMeldingerHandler = () => {
        disptach(authAction.setViewOrder(false));
    }

    /* Session wil expire by it self. But we can send the token back to the api
    * in order for it to make it expire now. */
    const sendLogoutRequest = async () => {
        const userData = {
            action: "userlogout",
            session: session,
        }

        try {
            const response = await fetch(API_URL + "/api/data", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(userData),
            });

            /* Get response */
            const status = await response.json();

            /* Check response */
            if (status.status === "error") {
                console.log(status.message);
            } else {
                /* Logout done */
                console.log(status.status);
            }
        } catch (error) {
            console.log(error + ". Klarte ikke og hente riktig data.");
        }
    }

    const logoutHandler = async () => {
        /* Hard reset of token */
        await sendLogoutRequest();

        /* Also removing it form local stoarge */
        localStorage.removeItem("session");

        /* Ressting staes */
        disptach(authAction.logout(true));
    }

    const navbar = (
        <Card className={classes.navbar}>
            <div className={classes['navbar-container']}>
                <button className={classes.button} onClick={viewOrderHandler}>Ordreliste</button>
            </div>
            <div className={classes['navbar-container']}>
                <button className={classes.button} onClick={viewMeldingerHandler}>Meldingsliste</button>
            </div>
            <div className={classes['navbar-container']}>
                <button className={classes.button} onClick={logoutHandler}>Logg ut</button>
            </div>
        </Card>
    );

    return (
        <Fragment>
            {!isLoggedIn && <LoginPanelPage />}
            {isLoggedIn && navbar}
            {isLoggedIn && viewOrder && <ListOrder />}
            {isLoggedIn && !viewOrder && <ListMeldinger />}
        </Fragment>
    );
}

export default PanelPage;