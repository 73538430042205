import classes from './SlideButton.module.css';
import IconHandler from "../../layout/handlers/IconHandler";
import {useEffect, useState} from "react";

const SlideButton = (props) => {
    /* State for controlling if button is highligthet or note for animation with use effect */
    const [btnIsHighlighted, setBtnIsHighlighted] = useState(false);

    /* Button Class for bump animation*/
    const buttonClass = btnIsHighlighted ? `${classes.btn} ${classes.bump}` : classes.btn;

    /* Using use Effect to remove highlight class after so it still will
    * be highliteh on a new push. */
    useEffect(() => {
        const timer = setTimeout(() => {
            setBtnIsHighlighted(false);
        }, 300);

        return () => {
            clearTimeout(timer);
        };
    }, [buttonClass])

    const onClickHandler = () => {
        setBtnIsHighlighted(true);
        props.onClick();
    }


    return (
        <button className={buttonClass}>
            <span className={classes.price}>{props.textOneHint}</span>
            <span className={classes['shopping-cart']}>
                <IconHandler icon={props.icon} />
                <div className={classes['shopping-cart__text']} onClick={onClickHandler}>{props.textTwo}</div></span>
            <div className={classes.buy}>{props.textOne}</div>
        </button>
    );
};

export default SlideButton;