import classes from './NavigationBar.module.css';

import {Card} from "@material-ui/core";
import {Link} from "react-router-dom";
import IconHandler from "../../layout/handlers/IconHandler";

const NavigationBar = (props) => {

    const content = (
        <div className={classes['nav-container']}>
            <span className={classes.intro}>Her er du:</span> <Link to="/nettbutikk">Nettbutikk</Link> <span><IconHandler icon="span" />
            {props.items.kategori_url && <Link to={props.items.kategori_url}>{props.items.kategori}</Link>}
            {props.items.kategori && !props.items.kategori_url && props.items.kategori}</span>
            {props.items.produkt && <span><IconHandler className={classes.icon} icon="span" />{props.items.produkt}</span>}
        </div>
    );


    return (
        <Card className={classes['card-container']}>
            {content}
        </Card>
    );
}

export default NavigationBar;
