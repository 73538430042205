import classes from './Footer.module.css';
import IconHandler from "./handlers/IconHandler";
import MenuBottom from "./menu/MenuBottom";
import {useSelector} from "react-redux";

const Footer = () => {

    /* Get isBig from store */
    const isBig = useSelector(state => state.content.isBig);

    /* On click handler */
    const onBagdeClick = (link) => {
        window.location = link;
    }

    const bigScreen = (
        <div className={classes['footer-container']}>

            {/* Footer tekst */}
            <div className={classes['footer-tekst']}>
                <a className={classes['links-list-a']} href="https://ladenettverket.no/">Ladenettverket
                    AS</a> &ndash; Tryggere lading gjort enkelt
            </div>

            {/* Footer Social */}
            <div className={classes['footer-social']}>
                <span className={classes.badge} onClick={onBagdeClick.bind(null, "https://www.linkedin.com/company/ladenettverket-as/")}>
                    <IconHandler icon="linkedin"/>
                </span>

                <span className={classes.badge} onClick={onBagdeClick.bind(null, "https://www.facebook.com/Ladenettverket/")}>
                    <IconHandler icon="facebook"/>
                </span>
            </div>
        </div>);

    return (
        <footer className={classes.footer}>
            {isBig && bigScreen}
            {!isBig && <MenuBottom />}
        </footer>
    );
}

export default Footer;