/* Design and code from: https://codepen.io/gabriellewee/pen/oBPGWd
 * Thanks to: #dailyui 008 */

import classes from './NotFoundPage.module.css';
import {Link} from "react-router-dom";

const NotFoundPage = () => {


    return (
        <main className={classes.main}>
            <div>
                <div>
                    <span>404 ERROR</span>
                    <span>siden finnes ikke</span>
                </div>

                <svg viewBox='0 0 200 600' className={classes.svg}>
                    <polygon points='118.302698 8 59.5369448 66.7657528 186.487016 193.715824 14 366.202839 153.491505 505.694344 68.1413353 591.044514 200 591.044514 200 8'></polygon>
                </svg>
            </div>

            <svg className={classes.crack} viewBox='0 0 200 600'>
                <polyline points='118.302698 8 59.5369448 66.7657528 186.487016 193.715824 14 366.202839 153.491505 505.694344 68.1413353 591.044514'></polyline>
            </svg>

            <div>
                <svg viewBox='0 0 200 600' className={classes.svg}>
                    <polygon points='118.302698 8 59.5369448 66.7657528 186.487016 193.715824 14 366.202839 153.491505 505.694344 68.1413353 591.044514 0 591.044514 0 8'></polygon>
                </svg>

                <div>
                    <span>Beklager dette!</span>
                    <span>Ta turen <b><Link to="/">hjem</Link></b> istdenfor?</span>
                </div>
            </div>
        </main>
    );
};

export default NotFoundPage;