import classes from "./LoadingModule.module.css";
import ReactDOM from "react-dom";
import React from "react";

const Backdrop = () => {
    return(
        <div className={classes.backdrop} />
    );
};

const Battery = () => {
    return (
        <div className={classes.content}>
            <div className={classes.battery}>
                <span className={classes['battery_item']}></span>
                <span className={classes['battery_item']}></span>
                <span className={classes['battery_item']}></span>
            </div>
            <div className={classes.subtitle}>Laster inn...</div>
        </div>
    );
}

const portalElement = document.getElementById('loading-module');

const LoadingModule = () => {
    return (
        <React.Fragment>
            {ReactDOM.createPortal(<Backdrop />, portalElement)}
            {ReactDOM.createPortal(<Battery />, portalElement)}
        </React.Fragment>
    );
}

export default LoadingModule;