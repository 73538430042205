import classes from './ProductCard.module.css';
import {Card} from "@material-ui/core";
import SlideButton from "../button/SlideButton";
import IconHandler from "../../layout/handlers/IconHandler";
import {useDispatch} from "react-redux";
import {cartAction} from "../../../store/cart-slice";
import {Link} from "react-router-dom";

const ProductCard = (props) => {
    /* Import disptach for addin to Redux */
    const dispatch = useDispatch();

    /* On add to CartHandler */
    const onAddToCartHandler = (newItem) => {
        /* If install item then something else add to cart */
        if(props.monter) {
            alert("Action for Install Item");
        } else {
            /* Adding Item to Cart */
            dispatch(cartAction.addItem({
                id: newItem.id,
                price: newItem.price,
            }));
        }
    }

    const Content = (
        <Card className={classes['card-container']}>
            <div className={classes['card-wrapper']}>
                <div className={classes['left-container']}>
                    <div className={classes['left-wrapper']}>
                        <div className={classes['img-container']}>
                            <img className={classes.img} alt={props.item[0]['media_alt']} src={props.item[0]['media_link']}/>
                        </div>
                    </div>
                </div>

                <div className={classes['right-container']}>
                    <div className={classes['right-wrapper']}>
                        <div>
                            <h1>{props.item[0]['navn']}</h1>
                            <h4>Kategori: <Link to={`/nettbutikk/kategori/${props.item[0]['kategori']}`} className={classes.link}>{props.item[0]['kategori']}</Link></h4>
                            <p>{props.item[0]['beskrivelse']}</p>

                            <h2>Pris: {props.item[0]['pris_ink_mva']} KR</h2>
                        </div>

                        <div className={classes['btn-container']}>
                            <SlideButton textOneHint={<IconHandler icon="deliver" />}
                                         textOne="Gjør dagens kupp nå!"
                                         textTwo="Legg i kurven"
                                         icon="store"
                                         onClick={onAddToCartHandler.bind(null, {id: props.item[0]['id_produkt'], price: props.item[0]['pris_ink_mva']})}/>
                        </div>
                    </div>
                </div>
            </div>
        </Card>
    );

    return Content;
}

export default ProductCard;