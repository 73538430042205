import classes from './Header.module.css';
import logo from '../assets/ladenettverket-logo-tekst.png';
import Menu from './menu/Menu';
import {Link} from "react-router-dom";
import {useSelector} from "react-redux";


const Header = () => {

    /* Get isBig from store */
    const isBig = useSelector(state => state.content.isBig);

    return (
        <header className={classes.header}>
            {/* Container div */}
            <div className={`${classes.container} ${!isBig && classes.center}`}>
                {/* Div for logo*/}
                <div className={classes.logo}>
                    <Link to="/"><img alt={"Ladenettverket AS &#8211; Tryggere lading gjort enkelt"} src={logo} className={classes['logo-img']}/></Link>
                </div>

                {isBig && <Menu />}
            </div>
        </header>
    );
};

export default Header;