/* Material UI Assets */
import StoreIcon from '@material-ui/icons/ShoppingBasket';
import LocationIcon from '@material-ui/icons/LocationOn';
import InstallIcon from '@material-ui/icons/PhonelinkSetup';
import ChargingIcon from '@material-ui/icons/BatteryChargingFullRounded';
import ContactIcon from '@material-ui/icons/Contacts';
import ErrorIcon from '@material-ui/icons/Error'
import PositionIcon from '@material-ui/icons/LocationOn';
import PhoneIcon from '@material-ui/icons/Phone';
import EmailIcon from '@material-ui/icons/Email';
import CorpoIcon from '@material-ui/icons/List';
import DeliverIcon from '@material-ui/icons/LocalShipping'
import ConfirmIcon from '@material-ui/icons/CheckBox'
import MenuIcon from '@material-ui/icons/Menu';
import FacebookIcon from "@material-ui/icons/Facebook";
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import SpanIcon from '@material-ui/icons/ArrowForwardIos';
import AccountIcon from '@material-ui/icons/AccountCircle'

/* Local Assets*/
//import FacebookIcon from "../../assets/FacebookIcon";
//import LinkedinIcon from "../../assets/LinkedinIcon";
//import MenuIcon from "../../assets/MenuIcon";
import HeaderCartButton from "../HeaderCartButton";

const IconHandler = (props) => {
    if(props.icon === 'store') { return <StoreIcon /> }
    if(props.icon === 'location') { return <LocationIcon /> }
    if(props.icon === 'install') { return <InstallIcon /> }
    if(props.icon === 'charge') { return <ChargingIcon /> }
    if(props.icon === 'contact') { return <ContactIcon /> }
    if(props.icon === 'facebook') { return <FacebookIcon /> }
    if(props.icon === 'linkedin') { return <LinkedInIcon /> }
    if(props.icon === 'menu') { return <MenuIcon /> }
    if(props.icon === 'cart-white') { return <HeaderCartButton color="white"/> }
    if(props.icon === 'pos') { return <PositionIcon /> }
    if(props.icon === 'phone') { return <PhoneIcon /> }
    if(props.icon === 'email') { return <EmailIcon /> }
    if(props.icon === 'corpo') { return <CorpoIcon /> }
    if(props.icon === 'deliver') { return <DeliverIcon />}
    if(props.icon === 'confirm') { return <ConfirmIcon />}
    if(props.icon === 'span') { return <SpanIcon style={{ fontSize: 10 }} />}
    if(props.icon === "account") {return <AccountIcon /> }

    return <ErrorIcon />;
}

export default IconHandler;