import classes from './ProgressBar.module.css';

const ProgressBar = (props) => {
    return (
        <ol className={`${classes.stepBar} ${classes.step3}`}>
            <li className={`${classes.step} ${props.step === "1" || props.step === "2" || props.step === "3" ? classes.current : ""}`}>KURVEN</li>
            <li className={`${classes.step} ${props.step === "2" || props.step === "3" ? classes.current : ""}`}>BEKREFT</li>
            <li className={`${classes.step} ${props.step === "3" ? classes.current : ""}`}>FULLFØRT</li>
        </ol>
    );
}

export default ProgressBar;