import classes from './ListItem.module.css';
import {useDispatch, useSelector} from "react-redux";
import {cartAction} from "../../../store/cart-slice";

const ListItem = (props) => {
    /* Need Following Innput
    *
    * productid = id of product
    * quantity = how many
    * totalprice = how much */

    /* Disptach for removing and adding */
    const dispatch = useDispatch();

    /* Get details */
    const productid = props.productid;
    const quantity = props.quantity;

    const products = useSelector(state => state.content.globalProducts);
    const currentProduct = products.find((product) => product['id_produkt'] === productid);

    /* Remove Item Handler */
    const removeItemHandler = () => {
        dispatch(cartAction.removeItemFromCart(productid));
    }

    /* Add Item Handler */
    const addItemHandler = () => {
        dispatch(cartAction.addItem({
            id: productid,
            price: currentProduct['pris_ink_mva'],
        }));
    }

    return (
        <li className={classes['cart-products-item']}>
            <div className={classes['img-container']}>
                <img src={currentProduct['media_link']} alt={currentProduct['media_alt']}/>
            </div>
            <div className={classes['cart-products-info']}>
                <h2>{currentProduct['navn']}</h2>
                <div className={classes['cart-products-summary']}>
                    <span className={classes['cart-products-summary-desc']}>{currentProduct['beskrivelse_short']}</span>
                </div>
            </div>
            <div className={classes['cart-products-actions']}>
                <div className={classes['cart-products-actions__row']}>
                    <span className={classes['cart-products-price']}>{currentProduct['pris_ink_mva']} NOK</span>
                    <span className={classes['cart-products-amount']}>x {quantity}</span>
                </div>
                <div className={classes['cart-products-actions__row']}>
                    <button onClick={removeItemHandler}>−</button>
                    <button onClick={addItemHandler}>+</button>
                </div>
            </div>
        </li>
    );
};

export default ListItem;