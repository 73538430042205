/* Custom Cart Slice for handeling items in cart */
import {createSlice} from "@reduxjs/toolkit";

/* Get local stoarge cart */
const localCart = JSON.parse(localStorage.getItem("cart"));

const cartSlice = createSlice({
    name: 'cart',

    initialState: {
        items: localCart ? localCart.items : [],
        totalQuantity: localCart ? localCart.totalQuantity : 0,
        totalAmount: localCart ? localCart.totalAmount : 0,
    },

    reducers: {
        addItem(state, action) {
            /* Adding Item from Payload
            * Item wil consist of:
            *
            * id: uniqe Item ID same as in globalProduct
            * price: price from globalProduct
            *
            * Other information is in globalProduct state. Such as name and so on. */
            const newItem = action.payload;

            /* Check if item allready is in cart */
            const existingItem = state.items.find(item => item.id === newItem.id);

            /* Update total item, we only add one and one, and it dosent affect a new item */
            state.totalQuantity++;

            /* Update Total Price we are adding one any way */
            state.totalAmount = state.totalAmount + newItem.price;

            /*If existing Item is not found is it undefeined/null so we add*/
            if (!existingItem) {
                state.items.push({
                    /* NB! Dont do this with only redux. Edting the raw state. It is okay now since we use
                     * redux-toolkit. It create a clone in the background for us that we edit. */
                    id: newItem.id,
                    price: newItem.price,
                    quantity: 1,
                    totalPrice: newItem.price
                });
            } else {
                /* Existing Item we update */
                existingItem.quantity++;
                existingItem.totalPrice = existingItem.totalPrice + newItem.price;
            }

            /* Store to local stoarge */
            localStorage.setItem("cart", JSON.stringify({items: state.items, totalQuantity: state.totalQuantity, totalAmount: state.totalAmount}));
        },

        removeItemFromCart(state, action) {
            /* Only ID will be pushed with pay load
             * id: uniqe product ID. Same as in SQL Database and Global Products */
            const id = action.payload;

            /* Finding current ITEM */
            const existingItem = state.items.find(item => item.id === id);

            /* Removing one from total */
            state.totalQuantity--;

            /* Removing one from total price */
            state.totalAmount = state.totalAmount - existingItem.price;

            /* If more than 1 in items, remove one. If not remove array */
            if (existingItem.quantity === 1) {
                /* Using filter and keeping only if not equal to id */
                state.items = state.items.filter((item) => item.id !== id);
            } else {
                existingItem.quantity--;
                existingItem.totalPrice = existingItem.totalPrice - existingItem.price;
            }

            /* Store to local stoarge */
            localStorage.setItem("cart", JSON.stringify({items: state.items, totalQuantity: state.totalQuantity, totalAmount: state.totalAmount}));
        },

        emptyCart(state, action) {
            /* Setting Cart to Init */
            state.items = [];
            state.totalQuantity = 0;
            state.totalAmount = 0;

            /* Removing from local stoarge */
            localStorage.removeItem("cart");
        }
    }
});

/* Export Actions */
export const cartAction = cartSlice.actions;

/* Export Reducer */
export default cartSlice.reducer;

