import { useParams } from "react-router-dom";
import {useSelector} from "react-redux";
import {Fragment} from "react";
import NotFoundPage from "../../../pages/NotFoundPage";
import ProductDetail from "./ProductDetail";
import ProductCard from "./ProductCard";
import SplitterCard from "../cards/SplitterCard";
import NavigationBar from "../cards/NavigationBar";

const ProductPage = () => {
    /* Get Products */
    const products = useSelector(state => state.content.globalProducts);

    /* Get acces to parms from url /somethign/:parma1/somethingmore/:parma2 and so on. */
    const params = useParams();

    /* Get product id */
    const { productid } = params;

    /* Check if product exist */
    const currentProduct = products.filter((item) => (item['id_produkt'].toString() === productid.toString()));

    /* Er den tom etter filter eksister produktet ikke vis error side */
    if(!currentProduct[0]) { return <NotFoundPage /> }

    /* Generate Extra Product list */
    let extraProductList = [];

    for(let x = 0; x < 4; x++) {
        let uniqe = false;
        while(!uniqe) {
            const product = products[(Math.random() * (products.length-1)).toFixed(0)];
            if(!extraProductList.includes(product)) {
                if(product['id_produkt'].toString() !== productid.toString()) {
                    extraProductList.push(product);
                    uniqe = true;
                }
            }
        }
    }

    const productList = extraProductList.map((items) => (
        <ProductDetail key={`productdetail_${items['id_produkt']}`} item={items}/>
    ));

    const navItems = {
        kategori_url: `/nettbutikk/kategori/${currentProduct[0]['kategori']}`,
        kategori: `${currentProduct[0]['kategori']}`,
        produkt: `${currentProduct[0]['navn']}`
    };

    /* Main Content */
    const mainContent = (
        <Fragment>
            {/* Navigation Bar*/}
            <NavigationBar items={navItems} />

            {/* BIG PRODUCT CARD */}
            <ProductCard item={currentProduct} />

            {/* Splitter */}
            <SplitterCard title="Vi tror du også vil like" img="https://dev.knoph.cc/uploads/default/ui/splitter-cover-small.jpg" alt="Elbil som blir ladet, fotograf er Andrew Roberts fra unsplash" />

            {/* Small Products */}
            <div className='body-card-container'>
                {productList}
            </div>
        </Fragment>
    );

    return mainContent;
}

export default ProductPage;