import {useParams} from "react-router-dom";
import {useSelector} from "react-redux";
import NotFoundPage from "../../../pages/NotFoundPage";
import ProductDetail from "./ProductDetail";
import {GenerateTitleCards} from "../../layout/handlers/GlobalHandlers";
import {Fragment} from "react";
import NavigationBar from "../cards/NavigationBar";

const KategoriPage = () => {
    /* Get Products and Static Content */
    const content = useSelector(state => state.content.staticContent);
    const products = useSelector(state => state.content.globalProducts);

    /* Get acces to parms from url /somethign/:parma1/somethingmore/:parma2 and so on. */
    const params = useParams();

    /* Get product id */
    const {kategori} = params;

    /* Get Kategori Items */
    const currentProducts = products.filter((item) => (item['kategori'].toString().trim() === kategori.toString().trim()));

    /* Er den tom etter filter eksister produktet ikke vis error side */
    if (!currentProducts[0]) {
        return <NotFoundPage/>
    }

    /* Map Products */
    const productList = currentProducts.map((items) => (
        <ProductDetail key={`productdetail_${items['id_produkt']}`} item={items}/>
    ));

    const navItems = {
        kategori: `${kategori}`,
    };


    return (
        <Fragment>
            <NavigationBar items={navItems} />
            <GenerateTitleCards data={content} group='cover' where='kategori' title={kategori}/>
            <div className='body-card-container'>
                {productList}
            </div>
        </Fragment>
    );
}

export default KategoriPage;