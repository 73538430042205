import {useState} from "react";
import classes from './FobindelsePage.module.css'
import {useSelector} from "react-redux";
import TextInput from "../../components/UI/formobjects/TextInput";
import WrapperInput from "../../components/UI/formobjects/WrapperInput";
import SplitForm from "../../components/UI/formobjects/SplitForm";
import ErrorModal from "../../components/UI/modals/ErrorModal";
import LoadingSpinner from "../../components/UI/spinners/LoadingSpinner";
import {Fragment} from "react";
import InfoSubmittetPage from "../../components/UI/cart/InfoSubmittetPage";
import InfoPara from "./InfoPara";
import InfoBlock from "./InfoBlock";

const ForbindelsePage = (props) => {

    /* State for handeling */
    const [isError, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isSubmit, setIsSubmit] = useState(false);

    /* Get API URL */
    const API_URL = useSelector(state => state.content.apiURL);

    /* Get info from store */
    const contactform = useSelector(state => state.content.contactForm);
    const globalinfo = useSelector(state => state.content.globalInfo);
    const globaltext = useSelector(state => state.content.globalText);

    /* Trigger error State - Each Innput form get the state. If True Change
    * thouch to true. Then the error will show. If they allready have thouch the
    * schema it ok, since the error loop have been set from the beginning and there
    * all ready is a error. We also show our own error message on the button. */
    const [triggerError, setTriggerError] = useState(null);

    /* Innputhandler
    * Verdiene her blir satt til "" som init.
    * Er noen verdier fortsatt "" når man prøver og sende inn
    * går det ikke gjennom. Verdiene oppdateres fra innput feltene når riktig får den verdien som blir
    * satt, ved feil blir den satt tilbake til "" */
    let userInnput = [];
    const innputHandler = (id, value) => {
        userInnput[id] = value;
    }

    /* Error Handler hide */
    const hideErrorHandler = () => {
        setError(null);
    }

    /* Button handler */
    const onClickInfoPage = () => {
        setIsSubmit(false);
    }

    /* On Submit Handler */
    const onSubmitHandler = async (event) => {
        event.preventDefault();

        /* Error fields */
        let ERROR_FIELD = "";
        let count = 0;

        /* Check if there are som errors */
        for (const x in userInnput) {
            if (userInnput[x] === "") {
                if (x !== "GDPR") {
                    count++;
                    ERROR_FIELD = `${ERROR_FIELD}\n${count}.${x}`
                } else {
                    ERROR_FIELD = `${ERROR_FIELD}\n\nGDPR MÅ VÆRE HUKET AV, DETTE ER NØDVENDIG FOR AT VI SKAL KUNNE TA FORBINDELSE MED DEG.`;
                }
            }

            if(x === "GARASJETYPE") {
                if(userInnput[x] === "VELG EN TYPE GARASJE") {
                    count++;
                    ERROR_FIELD = `${ERROR_FIELD}\n${count}.${x}`
                }
            }
        }

        if (ERROR_FIELD !== "") {
            setTriggerError(ERROR_FIELD);
            return;
        }

        /* Ingen error null stiller hvis det var noen. */
        setTriggerError(null);


        /* Start Spinner */
        setIsLoading(true);
        setError(null);

        /* Scroll to top */
        window.scrollTo(0, 0);

        /* Add action request and turn it into object */
        userInnput["action"] = "sendrequest";
        const JSONObject = JSON.stringify({...userInnput});

        /* Sending Data to server */
        try {
            /* Sending post JSON request */
            const response = await fetch(API_URL + "/api/data/", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSONObject,
            });

            /* Get respons data */
            const data = await response.json();

            /* Data consist of OK if sucess */
            console.log(data['status']);

            /* If we submittet then it is true. */
            setIsSubmit(true);

        } catch (error) {
            console.log(error.message + "Dette skyldes av forbindelsen til serveren er ustabil. Sjekk din forbindelse og prøv igjen.");
            setError(error.message + ". Dette kan skyldes av at forbindelsen til serveren er ustabil. Sjekk din forbindelse og prøv igjen.");
        }

        /* Use data to display ok and stop spinner */
        setIsLoading(false);
    }

    const mappedInnput = contactform.map((info) => {

        if (info.type === "button") {
            return (
                <WrapperInput key={`form_${info.name}`}>
                    <button key={`button_${info.name}`} onClick={onSubmitHandler}>{info.name}</button>
                    {triggerError && <p className={classes.error}>{info.error}{triggerError}</p>}
                </WrapperInput>);
        }

        return <TextInput key={`form_${info.name}`} unikey={`form_innput_${info.name}`} type={info.type} name={info.name} id={info.name} error={info.error}
                          label={info.label} hint={info.hint} handler={innputHandler} validation={info.val}
                          override={info.needed === 1 ? false : true} options={info.options}/>;

    });

    const mappedInfo = globalinfo.map((info) => (
        <InfoPara key={info.id} id={info.id} icon={info.icon} type={info.type} text={info.text} action={info.action} />
    ));

    /*TODO: Add Captacha formula to prevent misuse of the form. */
    const leftContent = (
        <Fragment>
            {isSubmit && <InfoSubmittetPage title="Melding sendt!" caption="Din melding er nå sendt, og vi vil ta forbindelse med deg fortløpende." button={onClickInfoPage} btext="Send en ny forespørsel!" />}
            {isLoading && !isSubmit && <LoadingSpinner/>}
            {!isLoading && !isSubmit && (<div className={classes['contact-wrapper']}>
                <h3 className={classes['h3']}> {globaltext["get_offer"]} </h3>

                <form className={classes.form}>
                    <div className={classes['contact-row']}>
                        {mappedInnput}
                    </div>
                </form>
            </div>)}
        </Fragment>
    );

    const rightContent = (
        <InfoBlock title={globaltext["find_us"]} info={globaltext["contact_info"]}>
            {mappedInfo}
        </InfoBlock>
    );

    return (
        <Fragment>
            {isError && !isLoading && <ErrorModal onClose={hideErrorHandler} message={isError}/>}
            <SplitForm leftContainer={leftContent} rightContainer={rightContent}/>
        </Fragment>
    );
};

export default ForbindelsePage;