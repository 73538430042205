import {Fragment} from "react";
import { GenerateInfoCards, GenerateTitleCards } from "../components/layout/handlers/GlobalHandlers";
import {useHistory} from "react-router-dom";
import {useSelector} from "react-redux";

const LaderePage = (props) => {
    /* Navigation */
    const history = useHistory();

    /* Get static content */
    const content = useSelector(state => state.content.staticContent);

    return (
        <Fragment>
            <GenerateTitleCards data={content} group='cover' where='our-charger' />
            <GenerateInfoCards data={content} group='charger' where='our-charger' history={history} />
        </Fragment>
    );
}


export default LaderePage;