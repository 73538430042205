import classes from './SplitterCard.module.css';
import {Box, Card, CardMedia} from "@material-ui/core";
import {useCoverCardMediaStyles} from '@mui-treasury/styles/cardMedia/cover';

const SplitterCard = (props) => {
    /* Treasury Styles Import */
    const mediaStyles = useCoverCardMediaStyles({bgPosition: 'top'});

    return (
        <Card className={classes.card}>
            <CardMedia
                classes={mediaStyles}
                image={props.img}
                alt={props.alt}
            />

            <Box py={3} px={2} className={classes.content}>
                    <p className={classes.title}>{props.title}</p>
            </Box>
        </Card>
    );
}

export default SplitterCard;