import classes from "./EmptyCartPage.module.css";
import MessageIcon from '@material-ui/icons/Email';

const InfoSubmittetPage = (props) => {
    return (
        <div className={classes.container}>
            <MessageIcon className={classes.icon} style={{fontSize: 90}} />
            <h2>{props.title}</h2>
            <h3>{props.caption}</h3>
            <button className={classes.knapp} onClick={props.button}>{props.btext}</button>
        </div>
    );
}

export default InfoSubmittetPage;