
import {Fragment} from "react";
import {useHistory} from "react-router-dom";
import MapCurrentStations from "../components/layout/interactive/MapCurrentStations";
import {GenerateInfoCards, GenerateHeaderCards} from "../components/layout/handlers/GlobalHandlers";
import {useSelector} from "react-redux";

const HomePage = () => {
    /* Navigation */
    const history = useHistory();

    /* Get Static Content */
    const content = useSelector(state => state.content.staticContent);

    return (
        <Fragment>
            <GenerateHeaderCards data={content} group='cover' where='home' />
            <GenerateInfoCards data={content} group='omoss' where='home' history={history} />
            <MapCurrentStations />
        </Fragment>
    );
};

export default HomePage;