import classes from './ViewOrdre.module.css';
import {useHistory, useParams} from "react-router-dom";
import InfoBlock from "../../forbindelse/InfoBlock";
import InfoPara from "../../forbindelse/InfoPara";
import {Fragment, useCallback, useEffect, useState} from "react";
import {useSelector} from "react-redux";
import NotFoundPage from "../../NotFoundPage";
import LoadingSpinner from "../../../components/UI/spinners/LoadingSpinner";
import ErrorModal from "../../../components/UI/modals/ErrorModal";
import SplitForm from "../../../components/UI/formobjects/SplitForm";
import ListOrderItem from "./ListOrderItem";
import SlideButton from "../../../components/UI/button/SlideButton";
import IconHandler from "../../../components/layout/handlers/IconHandler";

const ViewOrdrePage = () => {
    /* Get acces to parms from url /somethign/:parma1/somethingmore/:parma2 and so on. */
    const params = useParams();

    /* Get product id */
    const {token} = params;

    /* Navigation */
    const history = useHistory();

    /* Init Values for formData */
    const formDataInit = {
        user: [],
        id: "",
        date: "",
        products: [],
    }

    /* Default staus for fetching data */
    const [isError, setIsError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [display404, setDisplay404] = useState(false);
    const [formData, setFormData] = useState(formDataInit);

    /* Get API URL */
    const API_URL = useSelector(state => state.content.apiURL);

    const getOrder = useCallback(async () => {
        /* Start Init */
        setIsLoading(true);
        setIsError(null);

        const data = {
            action: 'order',
            token: token,
        }

        try {
            const response = await fetch(API_URL + "/api/data", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data),
            });

            /* Get response */
            const status = await response.json();

            /* Check response */
            if (status.status === "error") {
                setDisplay404(true);
            } else {
                setFormData(status.content);
            }


        } catch (error) {
            setIsError(error + ". Klarte ikke og hente riktig data.");
        }

        /* Ferdig vi kan avslutte lastingen*/
        setIsLoading(false);
    }, [API_URL, token]);

    useEffect(() => {
        getOrder();
    }, [getOrder]);

    /* Mapping Items */
    const cartItems = formData.products.map((currentItem) => {
        return (<ListOrderItem key={`list_order_${currentItem.product}`} id={currentItem.product}
                               amount={currentItem.amount}/>);
    });

    const onClickBack = () => {
        history.push("/panel");
    };

    /* Delte handler */
    const deleteRequest = async () => {
        /* Init */
        setIsError(null);
        setIsLoading(true);

        const data = {
            action: 'ordergoaway',
            token: token
        }

        try {
            const response = await fetch(API_URL + "/api/data", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data),
            });

            /* Get response */
            const status = await response.json();

            setIsLoading(false);

            /* Check response */
            if (status.status === "error") {
                setDisplay404(true);
            } else {
                history.push("/panel");
            }

        } catch (error) {
            setIsLoading(false);
            setIsError(error + ". Klarte ikke og hente riktig data.")
        }
    }

    const leftContent = (
        <div className={classes.wrapper}>
            <div>
                <h3 className={classes.h3}>Ordre #{formData['id']} </h3>
                <p><b>Orderen ble lagt inn:</b> {formData['date']}</p>
                {formData['note'] !== "" ? <p><b>Notat fra kunden:</b> {formData['note']}</p> : ""}
                <ul className={classes['cart-products']}>
                    {cartItems}
                </ul>
                <h2>Totalt: {formData.total} NOK</h2>
                <p>VIKTIG! Total summen er summen som ble registert den dagen kunden kjøpte produktet. Er det gjort
                    endringer i prisene etter dette på varene vil det være forskjell mellom totalprisen og prisene på
                    produktene lagt sammen. </p>
            </div>
            <div className={classes.content}>
                <button className={classes.button} onClick={onClickBack}>TILBAKE</button>
                <SlideButton textOne="SLETT ORDRE" textOneHint={<IconHandler icon=""/>}
                             textTwo="TRYKK FOR HER FOR Å BEKREFTE" icon="confirm"
                             onClick={deleteRequest}/>
            </div>
        </div>
    );

    const rightContent = (
        <InfoBlock title="Kundens opplysinger:"
                   info="Opplysingene her ble oppgitt når kunden sendte inn skjemaet og må behandles etter enhver tid gjeldene regelement for personvern.">
            <InfoPara id="1" icon="account" type="Navn"
                      text={`${formData['user']['fornavn']} ${formData['user']['etternavn']}`}/>
            <InfoPara id="2" icon="corpo" type="Firma" text={`${formData['user']['firmanavn']}`}/>
            <InfoPara id="3" icon="pos" type="Adresse"
                      text={`${formData['user']['gateadresse']}, ${formData['user']['postnummer']} ${formData['user']['sted']}`}/>
            <InfoPara id="4" icon="phone" type="Telefon" text={formData['user']['telefon']}/>
            <InfoPara id="5" icon="email" type="Epost" text={formData['user']['epost']}/>
            <InfoPara id="6" icon="confirm" type="GDPR samtykke registert" text={formData['user']['GDPR']}/>
        </InfoBlock>
    );

    return (
        <Fragment>
            {display404 && <NotFoundPage/>}
            {isLoading && !display404 && <LoadingSpinner/>}
            {isError && !isLoading && <ErrorModal onClose={getOrder} message={isError}/>}
            {!display404 && !isLoading && <SplitForm leftContainer={leftContent} rightContainer={rightContent}/>}
        </Fragment>
    );
}

export default ViewOrdrePage;