import classes from './KurvPage.module.css';
import ProgressBar from "../components/UI/cart/ProgressBar";
import EmptyCartPage from "../components/UI/cart/EmptyCartPage";
import {Fragment, useState} from "react";
import ListItem from "../components/UI/cart/ListItem";
import CartSummary from "../components/UI/cart/CartSummary";
import SplitForm from "../components/UI/formobjects/SplitForm";
import {useDispatch, useSelector} from "react-redux";
import WrapperInput from "../components/UI/formobjects/WrapperInput";
import TextInput from "../components/UI/formobjects/TextInput";
import {cartAction} from "../store/cart-slice";
import LoadingSpinner from "../components/UI/spinners/LoadingSpinner";

/* Promt watch the page, and if the user go to another page it tells us.
* We can theroe make somtehing happen, f.eks show waring if a user have started
* working on the form. */
import {Prompt} from "react-router";
import InfoSubmittetPage from "../components/UI/cart/InfoSubmittetPage";
import {useHistory} from "react-router-dom";
import ErrorModal from "../components/UI/modals/ErrorModal";

const KurvPage = () => {
    /* Get API URL */
    const API_URL = useSelector(state => state.content.apiURL);

    /* Dispatcher for cart slice */
    const dispatch = useDispatch();

    /* State to check if ready */
    const [isReady, setIsReady] = useState(false);

    /* History navigation */
    const history = useHistory();

    /* Trigger error State - Each Innput form get the state. If True Change
    * thouch to true. Then the error will show. If they allready have thouch the
    * schema it ok, since the error loop have been set from the beginning and there
    * all ready is a error. We also show our own error message on the button. */
    const [triggerError, setTriggerError] = useState(null);

    /* States for handeling submitting of form */
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(null);
    const [isSubmit, setIsSubmit] = useState(false);

    /* Get Checkout form */
    const cartform = useSelector(state => state.content.cartForm);

    /* Global Text */
    const globaltext = useSelector(state => state.content.globalText);

    /* Get Cart Slices */
    /*const items = DUMMY_DATA.items;
    const totalQuantity = DUMMY_DATA.totalQuantity;
    const totalAmount = DUMMY_DATA.totalAmount;*/

    const items = useSelector(state => state.cart.items);
    const totalQuantity = useSelector(state => state.cart.totalQuantity);
    const totalAmount = useSelector(state => state.cart.totalAmount);

    /* On Ready Click */
    const onReadyClickHandler = () => {
        /* Hvis Ready er falsk er vi på første steg hvis true kan vi submitte*/
        if(!isReady) {
            setIsReady(true);
        } else {
            onSubmitHandler();
        }
    }

    /* Verdiene her blir satt til "" som init.
    * "" === Feil, "--OK--" === OK, kan være tom.
    * Oppdateres i TextInput
    * Sjekkes også på API serveren of retunerer hvis feil. */
    let userInnput = [];
    const innputHandler = (id, value) => {
        userInnput[id] = value;
    };

    const onSubmitHandler = async () => {
        /* Error Fields */
        let ERROR_FIELD = "";
        let count = 0;

        /* Check if there are som errors */
        for (const x in userInnput) {
            if(userInnput[x] === "") {
                count++;
                ERROR_FIELD = `${ERROR_FIELD}\n${count}.${x}`
            }
        }

        if (ERROR_FIELD !== "") {
            setTriggerError(ERROR_FIELD);
            return;
        }

        /* Ingen error nulstiller den hvis det var noe */
        setTriggerError(null);

        /* Start Spinner */
        setIsLoading(true);
        setIsError(null);

        /* Scroll to top */
        window.scrollTo(0, 0);

        /* Henter varene og legger legger disse inn sammen med contact til en JSON */
        const JSONData = {
            action: "addcart",
            items: {...items},
            info: {...userInnput}
        };

        /* Try sending data to server */
        try {
            const response = await fetch(API_URL + "/api/data", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(JSONData),
            });

            /* Get respons data */
            const data = await response.json();

            /* Data consist of OK if sucess */
            console.log(data['status']);

            /* All good can set Submit */
            setIsSubmit(true);

            /* Setting is ready to false, so user can leace page without pop up */
            setIsReady(false);

            /* Also empty the cart since it have been submittet */
            dispatch(cartAction.emptyCart());

        } catch (error) {
            console.log(error.message + "Dette skyldes av forbindelsen til serveren er ustabil. Sjekk din forbindelse og prøv igjen.");
            setIsError(error.message + ". Dette kan skyldes av at forbindelsen til serveren er ustabil. Sjekk din forbindelse og prøv igjen.");
        }

        /* All went well display ok and stop spinner */
        setIsLoading(false);
    };

    const onClickTilbakeHandler = (event) => {
        event.preventDefault();
        const r = window.confirm('Er du sikker? Du mister alle dataene i skjemaet.');
        if(r) {
            setIsReady(false);
        }
    };

    /* Genererer Innput felt */
    const mappedInnput = cartform.map((info) => {

        if (info.type === "button") {
            return <WrapperInput key={`form_${info.name}`}>
                <button onClick={onClickTilbakeHandler}>{info.name}</button>
            </WrapperInput>;
        }

        return <TextInput key={`form_${info.name}`} unikey={`form_innput_${info.name}`} type={info.type} name={info.name} id={info.name} error={info.error}
                          label={info.label} hint={info.hint} handler={innputHandler} validation={info.val}
                          override={info.needed === 1 ? false : true} options={info.options} />;

    });

    /* Mapping Items */
    const cartItems = items.map((currentItem) => {
        return (<ListItem key={`list_item_${currentItem.id}`} productid={currentItem.id} quantity={currentItem.quantity} totalprice={currentItem.totalPrice} />);
    });

    /* Item List */
    const itemList = (
        <ul className={classes['cart-products']}>
            {cartItems}
        </ul>
    );

    /* Check Out Form */
    const checkOutForm = (
        <div className={classes['contact-wrapper']}>
            <h3 className={classes['h3']}> {globaltext["title_cart_form"]} </h3>

            <form className={classes.form}>
                <div className={classes['contact-row']}>
                    {mappedInnput}
                </div>
            </form>
        </div>
    );

    /* On click SubmitPage */
    const onClickInfoPage = () => {
        history.push("/ladere");
    }

    /* onSubmit page */
    const onSubmitPage = <InfoSubmittetPage title="Orderen din er sendt!" caption="Din ordre er nå sendt, og vi vil ta forbindelse med deg straks orderen er ferdigbehanldet" button={onClickInfoPage} btext="Mer info om våre ladere!" />;

    /* Cart */
    const cartForm = (
        <Fragment>
            <ProgressBar step={isSubmit ? "3" : isReady ? "2" : "1"}/>
            {isSubmit && onSubmitPage}
            {!isSubmit && <SplitForm leftContainer={isLoading ? <LoadingSpinner /> : isReady ? checkOutForm : itemList} rightContainer={<CartSummary onClick={onReadyClickHandler} isReady={isReady} totalAmount={totalAmount} error={triggerError} />} /> }
        </Fragment>
    );

    /* Error Handler hide */
    const hideErrorHandler = () => {
        setIsError(null);
    }

    return (
        <Fragment>
            {isError && !isLoading && <ErrorModal onClose={hideErrorHandler} message={isError}/>}
            <Prompt when={isReady} message={(location) => 'Er du sikker? Du mister alle dataene i skjemaet.'} />
            {totalQuantity === 0 && !isSubmit && <EmptyCartPage />}
            {(totalQuantity > 0 || isSubmit) && cartForm}
        </Fragment>
    );
};

export default KurvPage;