import {useHistory, useParams} from "react-router-dom";
import SplitForm from "../../../components/UI/formobjects/SplitForm";
import InfoBlock from "../../forbindelse/InfoBlock";
import InfoPara from "../../forbindelse/InfoPara";
import classes from "./ViewRequestPage.module.css";
import SlideButton from "../../../components/UI/button/SlideButton";
import IconHandler from "../../../components/layout/handlers/IconHandler";
import {useCallback, useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {Fragment} from "react";
import NotFoundPage from "../../NotFoundPage";
import LoadingSpinner from "../../../components/UI/spinners/LoadingSpinner";
import ErrorModal from "../../../components/UI/modals/ErrorModal";

const ViewRequestPage = () => {

    /* Navigation */
    const history = useHistory();

    /* Init Values for formData */
    const formDataInit = {
        user: [],
        id: "",
        request: "",
        type: ""
    }

    /* Default staus for fetching data */
    const [isError, setIsError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [display404, setDisplay404] = useState(false);
    const [formData, setFormData] = useState(formDataInit);

    /* Get acces to parms from url /somethign/:parma1/somethingmore/:parma2 and so on. */
    const params = useParams();

    /* Get product id */
    const {token} = params;

    /* Get API URL */
    const API_URL = useSelector(state => state.content.apiURL);

    /* Delete Request */
    const deleteRequest = async () => {
        /* Init */
        setIsError(null);
        setIsLoading(true);

        const data = {
            action: 'requestgoaway',
            token: token
        }

        try {
            const response = await fetch(API_URL + "/api/data", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data),
            });

            /* Get response */
            const status = await response.json();

            setIsLoading(false);

            /* Check response */
            if (status.status === "error") {
                setDisplay404(true);
            } else {
                history.push("/panel");
            }

        } catch (error) {
            setIsLoading(false);
            setIsError(error + ". Klarte ikke og hente riktig data.")
        }
    }

    /* Get info based on token Request */
    const getInfo = useCallback(async () => {
        /* Starting Loading */
        setIsError(null);
        setIsLoading(true);

        const data = {
            action: 'request',
            token: token,
        }

        try {
            const response = await fetch(API_URL + "/api/data", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data),
            });

            /* Get response */
            const status = await response.json();

            /* Check response */
            if (status.status === "error") {
                setDisplay404(true);
            } else {
                setFormData(status.content);
            }
        } catch (error) {
            setIsError(error + ". Klarte ikke og hente riktig data.")
        }

        /* End loading */
        setIsLoading(false);
    }, [token, API_URL]);

    /* On Page Load try to get token data */
    useEffect(() => {
        getInfo();
    }, [getInfo]);

    const onClickBack = () => {
        history.push("/panel");
    };

    const leftContent = (
        <div className={classes.wrapper}>
            <div>
                <h3 className={classes.h3}>Forespørsel #{formData['id']} </h3>

                <h3>Melding fra kunden: </h3>
                <p>{formData['request']}</p>

                <p><b>Type innstalasjonsplass:</b> {formData['type']}</p>
                <p><b>Melding motatt:</b> {formData['date']}</p>
            </div>

            <div className={classes.content}>
                <button className={classes.button} onClick={onClickBack}>TILBAKE</button>
                <SlideButton textOne="SLETT FORESPØRSEL" textOneHint={<IconHandler icon=""/>}
                             textTwo="TRYKK FOR HER FOR Å BEKREFTE" icon="confirm"
                             onClick={deleteRequest}/>
            </div>
        </div>
    );

    const rightContent = (
        <InfoBlock title="Kundens opplysinger:"
                   info="Opplysingene her ble oppgitt når kunden sendte inn skjemaet og må behandles etter enhver tid gjeldene regelement for personvern.">
            <InfoPara id="1" icon="account" type="Navn"
                      text={`${formData['user']['fornavn']} ${formData['user']['etternavn']}`}/>
            <InfoPara id="2" icon="corpo" type="Firma" text={`${formData['user']['firmanavn']}`}/>
            <InfoPara id="3" icon="pos" type="Adresse"
                      text={`${formData['user']['gateadresse']}, ${formData['user']['postnummer']} ${formData['user']['sted']}`}/>
            <InfoPara id="4" icon="phone" type="Telefon" text={formData['user']['telefon']}/>
            <InfoPara id="5" icon="email" type="Epost" text={formData['user']['epost']}/>
            <InfoPara id="6" icon="confirm" type="GDPR samtykke registert" text={formData['user']['GDPR']}/>
        </InfoBlock>
    );

    return (
        <Fragment>
            {display404 && <NotFoundPage/>}
            {isLoading && !display404 && <LoadingSpinner/>}
            {isError && !isLoading && <ErrorModal onClose={getInfo} message={isError}/>}
            {!display404 && !isLoading && <SplitForm leftContainer={leftContent} rightContainer={rightContent}/>}
        </Fragment>
    );
}

export default ViewRequestPage;