import classes from './Menu.module.css';
import HeaderCartButton from "../HeaderCartButton";
import {NavLink} from "react-router-dom";
import {useSelector} from "react-redux";


const Menu = () => {

    /* Get menuItem from store */
    const menuItem = useSelector(state => state.content.headerMenu);

    const headerLink = menuItem.map((item) => (
        <li key={item.id} className={classes['links-list-li']}>
            <span className={classes['links-list-span']}><NavLink className={classes['links-list-a']} activeClassName={classes['links-list-active']} to={item.action}>{item.name}</NavLink></span>
        </li>
    ));

    const cartButton = (<li key="cart-button-list" className={classes['links-list-li']}>
            <HeaderCartButton />
        </li>
        );

    return (
        <div className={classes.links}>
            <ul className={classes['links-list']}>
                {headerLink}
                {cartButton}
            </ul>
        </div>
    );
};

export default Menu;