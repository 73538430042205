import classes from "./EmptyCartPage.module.css";
import StoreIcon from '@material-ui/icons/ShoppingBasket';
import {useHistory} from "react-router-dom";

const EmptyCartPage = () => {

    /* Navigator */
    const history = useHistory();

    /* Button Click */
    const onFortsettClick = () => {
        history.push("/nettbutikk");
    }

    return (
        <div className={classes.container}>
            <StoreIcon className={classes.icon} style={{fontSize: 90}} />
            <h2>Oi, har du sett!</h2>
            <h3>Det ser ut som at kurven din er tom! Før du sjekker ut må du velge noen produkter i vår nettbutikk! Hva venter du på ta turen dit nå. </h3>
            <button className={classes.knapp} onClick={onFortsettClick}>Fortsett å handle!</button>
        </div>
    );
}

export default EmptyCartPage;