import classes from './HeaderCard.module.css';
import {Card, CardMedia, Box} from "@material-ui/core";
import {Info, InfoCaption, InfoSubtitle, InfoTitle} from "@mui-treasury/components/info";
import {useCoverCardMediaStyles} from '@mui-treasury/styles/cardMedia/cover';

const HeaderCard = (props) => {
    /* Treasury Styles Import */
    const mediaStyles = useCoverCardMediaStyles({bgPosition: 'top'});

    return (
        <Card className={classes.card}>
            <CardMedia
                classes={mediaStyles}
                image={props.image}
                alt={props.alt}
            />

            <Box py={3} px={2} className={classes.content}>
                <Info>
                    <InfoSubtitle className={classes.subtitle}>{props.subtitle}</InfoSubtitle>
                    <InfoTitle className={classes.title}>{props.title}</InfoTitle>
                    <InfoCaption className={classes.caption}>{props.caption}</InfoCaption>
                </Info>
            </Box>
        </Card>
    );
}

export default HeaderCard;