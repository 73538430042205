import classes from './ListeMeldinger.module.css';
import {Fragment, useCallback, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import ListPanelItems from "../ListPanelItems";
import ErrorModal from "../../../components/UI/modals/ErrorModal";
import SearchSortBar from "../SearchSortBar";
import LoadingSpinner from "../../../components/UI/spinners/LoadingSpinner";
import EmptyPage from "../EmptyPage";
import {authAction} from "../../../store/auth-slice";

const ListMeldinger = () => {
    /* Default staus for fetching data */
    const [isError, setIsError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [meldingData, setMeldingData] = useState([]);
    const [isFilter, setIsFilter] = useState("");
    const [howSort, setHowSort] = useState("az");

    /* Get API URL */
    const API_URL = useSelector(state => state.content.apiURL);

    /*Get disptach */
    const disptach = useDispatch();

    /* Request data */
    const user = useSelector(state => state.auth.user);
    const session = useSelector(state => state.auth.session);

    const getMeldingerList = useCallback(async () => {
        /*Init */
        setIsLoading(true);
        setIsError(null);

        /* Data object */
        const userData = {
            action: "requestlist",
            session: session,
            user: user,
        }

        try {
            const response = await fetch(API_URL + "/api/data", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(userData),
            });

            /* Get response */
            const status = await response.json();

            /* Check response */
            if (status.status === "error") {
                setIsError(status.message);
            } else {
                setMeldingData(status.content);
            }

        } catch (error) {
            setIsError(error + ". Klarte ikke og hente riktig data.");
        }

        setIsLoading(false);
    }, [API_URL, user, session]);

    useEffect(() => {
        getMeldingerList();
    }, [getMeldingerList])

    let filterList = meldingData;

    /* Filter Data */
    if(isFilter !== "") {
        filterList = meldingData.filter((items) => {
            return items['user']['fornavn'].includes(isFilter) || items['user']['etternavn'].includes(isFilter) || items['user']['epost'].includes(isFilter) || (items['user']['fornavn']+ " " + items['user']['etternavn']).includes(isFilter);
        });
    }

    if(howSort === "ef") {
        /* Sort Data Number Low - High */
        filterList.sort((a, b) => {
            return a['request_id'] - b['request_id'];
        });
    }

    else {
        /* Sort Data Number High - Low */
        filterList.sort((a, b) => {
            return b['request_id'] - a['request_id'];
        });
    }

    const mappedList = filterList.map((items) => {
        const cardInfo = {
            type: "Melding",
            nummer: items['request_id'],
            navn: items['user']['fornavn'] + " " + items['user']['etternavn'],
            sub1: "Type",
            sub1x: items['request_type'],
            sub2: "Epost",
            sub2x: items['user']['epost'],
            date: new Date(items['request_date'].replace(/-/g, '/')),
            url: "/panel/request/" + items['request_token']
        }

        return <ListPanelItems key={items['request_id']} cardinfo={cardInfo}/>
    });

    const list = (
        <div className={classes['item-container']}>
            {mappedList}
        </div>
    );

    const loggutHandler = () => {
        disptach(authAction.logout(true));
    };

    const onFilterHandler = (value) => {
        setIsFilter(value);
    }

    const onChangeSort = (value) => {
        setHowSort(value);
    }

    return (
        <Fragment>
            {isError && !isLoading &&
            <ErrorModal onClose={getMeldingerList} message={isError} logginn={loggutHandler}/>}
            <SearchSortBar onChangeFilter={onFilterHandler} onChangeSort={onChangeSort}/>
            {isLoading && <LoadingSpinner/>}
            {!isLoading && !isError && mappedList.length !== 0 && list}
            {!isLoading && !isError && mappedList.length === 0 && <EmptyPage order={false} />}
        </Fragment>
    );
};

export default ListMeldinger;