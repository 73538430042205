/* Custom Content Slice for handeling site content */
import {createSlice} from "@reduxjs/toolkit";

const contentSlice = createSlice({
    name: 'content',

    initialState: {
        headerMenu: [],
        footerMenu: [],
        chargerPosition: [],
        staticContent: [],
        contactForm: [],
        cartForm: [],
        globalInfo: [],
        globalText: [],
        globalProducts: [],
        globalProductsInstall: [],
        isBig: window.innerWidth >= 1200,
        apiURL: "https://demo.knoph.cc",
    },

    reducers: {
        updateHeaderMenu(state, action) {
            state.headerMenu = action.payload;
        },

        updateFooterMenu(state, action) {
            state.footerMenu = action.payload;
        },

        updateChargerPosition(state, action) {
            state.chargerPosition = action.payload;
        },

        updateStaticContent(state, action) {
            state.staticContent = action.payload;
        },

        updateContactForm(state, action) {
            state.contactForm = action.payload;
        },

        updateGlobalInfo(state, action) {
            state.globalInfo = action.payload;
        },

        updateGlobalText(state, action) {
            state.globalText = action.payload;
        },

        updateGlobalProducts(state, action) {
            state.globalProducts = action.payload;
        },

        updateGlobalProductsInstall(state, action) {
            state.globalProductsInstall = action.payload;
        },

        updateCartForm(state, action) {
            state.cartForm = action.payload;
        },

        updateIsBig(state, action) {
            state.isBig = action.payload;
        }
    }
});

/* Export Actions */
export const contentAction = contentSlice.actions;

/* Export Reducer */
export default contentSlice.reducer;