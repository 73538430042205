import classes from './LoginPanelPage.module.css';
import {useState} from "react";
import LoadingSpinner from "../../components/UI/spinners/LoadingSpinner"
import {useDispatch, useSelector} from "react-redux";
import {authAction} from "../../store/auth-slice";
import {sha512} from "js-sha512";

const LoginPanelPage = () => {

    /* Init Data */
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [isError, setIsError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    /* Get API URL */
    const API_URL = useSelector(state => state.content.apiURL);

    /* Const use disptach */
    const disptach = useDispatch();

    const getSession = async () => {
        setIsLoading(true);
        setIsError(null);

        /* Data object */
        const userData = {
            action: "userlogin",
            username: username,
            password: sha512(password),
        }

        try {
            const response = await fetch(API_URL + "/api/data", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(userData),
            });

            /* Get response */
            const status = await response.json();

            /* Check response */
            if (status.status === "error") {
                setIsError(status.message);
                setIsLoading(false);
            } else {
                /* Login ok create object */
                const loginobject = {
                    user: username,
                    session: status.content.session,
                    session_expire: status.content.session_expire,
                    last_login: status.content.last_login,
                    isLoggedIn: true,
                }

                /* Send Object to Redux */
                disptach(authAction.userLogin(loginobject));
                console.log("DONE");
            }
        } catch (error) {
            setIsError(error + ". Klarte ikke og hente riktig data.");

            /* We set setIsLoading to false here. We dont need to set it to false if we succedd with login.
            * This is because this will be unmonted and it only produce an error We dont need to set is Loadint to false
            * only if we have an error so the user can try to login once more. */
            setIsLoading(false);
        }
    }

    const loginHandler = async (event) => {
        event.preventDefault();
        await getSession();
    }

    const onChangeUsernameHandler = (event) => {
        setUsername(event.target.value);
    }

    const onChangePasswordHandler = (event) => {
        setPassword(event.target.value);
    }

    const loginForm = (
        <section className={classes.auth}>
            <form>
                <div className={classes.control}>
                    <input type='text' id='email' placeholder="BRUKERNAVN" className={classes.input} onChange={onChangeUsernameHandler} required />
                </div>
                <div className={classes.control}>
                    <input type='password' id='password' placeholder="PASSORD" className={classes.input} onChange={onChangePasswordHandler} required />
                </div>
                <div className={classes.actions}>
                    <button onClick={loginHandler}>Logg inn</button>
                </div>

                {isError && <p className={classes.error}>{isError}</p>}
            </form>
        </section>
    );

    return isLoading ? <LoadingSpinner /> : loginForm;
}

export default LoginPanelPage;