import classes from './ListPanelItems.module.css';
import {Card} from "@material-ui/core";
import {useHistory} from "react-router-dom";

const ListPanelItems = (props) => {

    /* Get cardinfo object
    * type: Melding/Ordre
    * nummer: 1-2-3
    * navn: Ola Nordmann
    * sub1: Antall Varer
    * sub1x: 1-2-3
    * sub2: Epost
    * sub2x: ola@nordmann.
    * date: dateObject */

    const month = props.cardinfo.date.toLocaleString('no', {month: 'long'});
    const day = props.cardinfo.date.toLocaleString('no', {day: '2-digit'});
    const year = props.cardinfo.date.getFullYear();

    const history = useHistory();

    const onClickHandler = () => {
        history.push(props.cardinfo.url);
    }

    return (
        <Card className={classes['card-container']} onClick={onClickHandler}>
            <div className={classes['panel-date']}>
                <div className={classes['panel-date__month']}>{month}</div>
                <div className={classes['panel-date__year']}>{year}</div>
                <div className={classes['panel-date__day']}>{day}</div>
            </div>

            <div>
                <h2><b>{props.cardinfo.type} #{props.cardinfo.nummer}</b> - {props.cardinfo.navn}</h2>
                <div className={classes['card-container__beskrivelse']}>
                    <div><b>{props.cardinfo.sub1}:</b> {props.cardinfo.sub1x}</div>
                    <div><b>{props.cardinfo.sub2}:</b> {props.cardinfo.sub2x}</div>
                </div>
            </div>
        </Card>
    );
}

export default ListPanelItems;