/* Handlers for global use */
import HeaderCardTitle from "../../UI/cards/HeaderCardTitle";
import CustomCard from "../../UI/cards/CustomCard";
import HeaderCard from "../../UI/cards/HeaderCard";

/* Generate header card */
export const GenerateHeaderCards = (props) => {
    const headerObjects = props.data.map((content) => {
        if (content['content_group'] === props.group && content['content_where'] === props.where) {
            return (
                <HeaderCard key={`header_${content.id}`}
                            image={content["media_link"]}
                            alt={content["media_alt"]}
                            title={content.title}
                            subtitle={content.subtitle}
                            caption={content.caption}/>
            );
        } else {
            return [];
        }
    });

    return headerObjects;
}

/* Generation of Title Cards */
export const GenerateTitleCards = (props) => {
    const cardTitle = props.data.map((content) => {
        if (!props.top) {
            if (content['content_group'] === props.group && content['content_where'] === props.where) {
                return (
                    <HeaderCardTitle key={`card_title_${content.id}`} title={!props.title ? content.title : props.title} subtitle={content.subtitle}
                                     img={content['media_link']} alt={content['media_alt']}/>);
            } else {
                return [];
            }
        } else {
            if (content['content_group'] === props.group && content['content_where'] === props.where && content['content_place'] === 'top') {
                return (
                    <HeaderCardTitle key={`card_title_${content.id}`} title={!props.title ? content.title : props.title} subtitle={content.subtitle}
                                     img={content['media_link']} alt={content['media_alt']}/>);
            } else {
                return [];
            }
        }
    });

    return cardTitle;
}

/* Generate Info Cards */
export const GenerateInfoCards = (props) => {
    const cardObjects = props.data.map((content) => {
        if (content['content_group'] === props.group && content['content_where'] === props.where) {
            return (<CustomCard key={`card_${content.id}`}
                                imgAlt={content['media_alt']}
                                image={content['media_link']}
                                title={content.title}
                                description={content.caption} B1Text={content['button_text']}
                                B1OnClick={actionHandler.bind(null, content['button_action'], props.history)}/>);
        } else {
            return [];
        }
    });

    return (
        <div className='body-card-container'>
            {cardObjects}
        </div>
    );
}

/* Action Handler */
export const actionHandler = (action, history) => {
    /* Splitting Action */
    const myAction = action.split("|");

    /* Calling function */
    if (myAction[0] === "call") {
        /* Return Call Function */
        window.location = `tel:${myAction[1]}`;
    }

    /* Link function */
    if (myAction[0] === "link") {
        /* Return Link */
        history.push(`/${myAction[1]}`);
    }

    /* Download */
    if (myAction[0] === "download") {
        /* Return Call Function */
        const download = `${window.location.origin}/${myAction[1]}`;
        window.location.href = download;
    }

    return null;
}