import classes from './ListOrder.module.css';
import {Fragment, useCallback, useEffect, useState} from "react";
import SearchSortBar from "../SearchSortBar";
import ListPanelItems from "../ListPanelItems";
import {useDispatch, useSelector} from "react-redux";
import LoadingSpinner from "../../../components/UI/spinners/LoadingSpinner";
import ErrorModal from "../../../components/UI/modals/ErrorModal";
import EmptyPage from "../EmptyPage";
import {authAction} from "../../../store/auth-slice";


const ListOrder = () => {
    /* Default staus for fetching data */
    const [isError, setIsError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [orderData, setOrderData] = useState([]);
    const [isFilter, setIsFilter] = useState("");
    const [howSort, setHowSort] = useState("az");

    /* Get API URL */
    const API_URL = useSelector(state => state.content.apiURL);

    /* Request data */
    const user = useSelector(state => state.auth.user);
    const session = useSelector(state => state.auth.session);

    /* Setup Disptach */
    const disptach = useDispatch();

    const getOrderList = useCallback(async () => {
        /* Init */
        setIsLoading(true);
        setIsError(null);

        /* Data Object */
        const userData = {
            action: "orderlist",
            session: session,
            user: user,
        };

        try {
            const response = await fetch(API_URL + "/api/data", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(userData),
            });

            /* Get response */
            const status = await response.json();

            /* Check response */
            if (status.status === "error") {
                setIsError(status.message);
            } else {
                setOrderData(status.content);
            }

        } catch (error) {
            setIsError(error + ". Klarte ikke og hente riktig data.");
        }

        /* Ferdig avslutter */
        setIsLoading(false);
    }, [API_URL, user, session]);

    /* Run once on start */
    useEffect(() => {
        getOrderList();
    }, [getOrderList]);

    let filterList = orderData;

    /* Filter Data */
    if(isFilter !== "") {
        filterList = orderData.filter((items) => {
            return items['user']['fornavn'].includes(isFilter) || items['user']['etternavn'].includes(isFilter) || items['user']['epost'].includes(isFilter) || (items['user']['fornavn']+ " " + items['user']['etternavn']).includes(isFilter);
        });
    }

    if(howSort === "ef") {
        /* Sort Data Number Low - High */
        filterList.sort((a, b) => {
            return a['order_id'] - b['order_id'];
        });
    }

    else {
        /* Sort Data Number High - Low */
        filterList.sort((a, b) => {
            return b['order_id'] - a['order_id'];
        });
    }

    const mappedList = filterList.map((items) => {
        const cardInfo = {
            type: "Ordre",
            nummer: items['order_id'],
            navn: items['user']['fornavn'] + " " + items['user']['etternavn'],
            sub1: "Totalt",
            sub1x: items['order_total'] + " KR",
            sub2: "Epost",
            sub2x: items['user']['epost'],
            date: new Date(items['order_date'].replace(/-/g, '/')),
            url: "/panel/order/" + items['order_token']
        }

        return <ListPanelItems key={items['order_id']} cardinfo={cardInfo} />
    });

    const list = (
        <div className={classes['item-container']}>
            {mappedList}
        </div>
    );

    const loggutHandler = () => {
        disptach(authAction.logout(true));
    }

    const onFilterHandler = (value) => {
        setIsFilter(value);
    }

    const onChangeSort = (value) => {
        setHowSort(value);
    }

    return (
        <Fragment>
            {isError && !isLoading && <ErrorModal onClose={getOrderList} message={isError} logginn={loggutHandler} />}
            <SearchSortBar onChangeFilter={onFilterHandler} onChangeSort={onChangeSort}/>
            {isLoading && <LoadingSpinner />}
            {!isLoading && !isError && mappedList.length !== 0 && list}
            {!isLoading && !isError && mappedList.length === 0 && <EmptyPage order={true} />}
        </Fragment>
    );
}

export default ListOrder;