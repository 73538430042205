import Modal from "./Modal";
import classes from './ErrorModal.module.css';
import logo from '../../assets/ladenettverket-logo-tekst.png';

const ErrorModal = (props) => {
    return (
        <Modal onClose={props.onClose}>
            <div className={classes.container}>
                <img src={logo} alt="En feil har oppstått" className={classes.image}/>
                <p className={classes['message__title']}>Oi, det har oppstått et problem.</p>
                <p className={classes['message__content']}>{props.message}</p>
                <p className={classes['message__footer']}>Gjentar problemet seg ta forbindelse med oss på telefon: +47
                    XX XX XX XX.</p>
                <div className={classes['button_container']}>
                    <button className={classes.button} onClick={props.onClose}>Prøv igjen</button>
                    {props.logginn && <button className={classes.button} onClick={props.logginn}>Logg inn</button>}
                </div>
            </div>
        </Modal>
    );
}

export default ErrorModal;