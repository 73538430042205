import classes from "../../components/UI/cart/EmptyCartPage.module.css";
import StoreIcon from '@material-ui/icons/ShoppingBasket';
import MeldingIcon from '@material-ui/icons/Email';

const EmptyPage = (props) => {
    return (
        <div className={classes.container}>
            {props.order && <StoreIcon className={classes.icon} style={{fontSize: 90}}/>}
            {!props.order && <MeldingIcon className={classes.icon} style={{fontSize: 90}}/>}
            <h2>Oi, har du sett!</h2>
            <h3>Enten så har du ingen meldinger, eller så passet ikke søket ditt med noen av dem.</h3>
        </div>
    );
}

export default EmptyPage;