import classes from './HeaderCardTitle.module.css';
import {Card, CardMedia, Box} from "@material-ui/core";
import {Info} from "@mui-treasury/components/info";
import {useCoverCardMediaStyles} from '@mui-treasury/styles/cardMedia/cover';

const HeaderCardTitle = (props) => {
    /* Treasury Styles Import */
    const mediaStyles = useCoverCardMediaStyles({bgPosition: 'top'});

    return (
        <Card className={classes.card}>
            <CardMedia
                classes={mediaStyles}
                image={props.img}
                alt={props.alt}
            />

            <Box py={3} px={2} className={classes.content}>
                <Info>
                    <p className={classes.subtitle}>{props.subtitle}</p>
                    <p className={classes.title}>{props.title}</p>
                </Info>
            </Box>
        </Card>
    );
}

export default HeaderCardTitle;