import {Fragment} from "react";
import {GenerateTitleCards} from "../components/layout/handlers/GlobalHandlers";
import InfoCard from "../components/UI/cards/InfoCard";
import {useSelector} from "react-redux";

const LokasjonerPage = (props) => {

    /* Get Static Content */
    const content = useSelector(state => state.content.staticContent);

    const betalingLadere = content.map((content) => {
        if (content['content_group'] === 'betaling' && content['content_where'] === "etablerte" && content['content_type'] === "info-card") {
            return <InfoCard key={`infocard_${content['id']}`} alt={content['media_alt']} img={content['media_link']} title={content['title']} caption={content['caption']}/>
        } else {
            return [];
        }
    });

    const boretLadere = content.map((content) => {
        if (content['content_group'] === 'boret' && content['content_where'] === "etablerte" && content['content_type'] === "info-card") {
            return <InfoCard key={`infocard_${content['id']}`} alt={content['media_alt']} img={content['media_link']} title={content['title']} caption={content['caption']}/>
        } else {
            return [];
        }
    });

    const hjemmeLadere = content.map((content) => {
        if (content['content_group'] === 'hjemme' && content['content_where'] === "etablerte" && content['content_type'] === "info-card") {
            return <InfoCard key={`infocard_${content['id']}`} alt={content['media_alt']} img={content['media_link']} title={content['title']} caption={content['caption']}/>
        } else {
            return [];
        }
    });

    return (
        <Fragment>
            <GenerateTitleCards data={content} group='betaling' where='etablerte' top/>
            <div className='body-card-container'>
                {betalingLadere}
            </div>

            <GenerateTitleCards data={content} group='boret' where='etablerte' top/>
            <div className='body-card-container'>
                {boretLadere}
            </div>

            <GenerateTitleCards data={content} group='hjemme' where='etablerte' top/>
            <div className='body-card-container'>
                {hjemmeLadere}
            </div>
        </Fragment>
    );
}

export default LokasjonerPage;