import classes from './InfoCard.module.css';
import {Card} from "@material-ui/core";

const InfoCard = (props) => {
    return (
        <Card className={classes['info-card']}>
            <div className={classes['info-card-container']}>
                <div className={classes['info-card-container__wrapper']}>
                    <img className={classes['info-card-container__img']} alt={props.alt}
                         src={props.img}/>/>

                    <div className={classes['info-card-container__info']}>
                        <h1>{props.title}</h1>
                        <p>{props.caption}</p>
                    </div>
                </div>
            </div>
        </Card>
    );
};

export default InfoCard;