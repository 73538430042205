import classes from "./PersonvernPage.module.css";
import {useSelector} from "react-redux";
import {Card} from "@material-ui/core";

const PersonvernPage = () => {
    const globalContent = useSelector(state => state.content.staticContent);

    /* Filter out Personvern and sort it */
    const personvernText = globalContent.filter((item) => {
        return item['content_group'] === "personvern";
    }).sort((a, b) => {
        return a['content_place'] - b['content_place'];
    });

    const personvernMapped = personvernText.map((item) => {
        if (item['content_place'] === "1") {
            return (
                <div>
                    <h1>{item['title']}</h1>
                    <p>{item['caption']}</p>
                </div>
            );
        } else {
            return (
                <div>
                    <h3>{item['title']}</h3>
                    <p>{item['caption']}</p>
                </div>
            );
        }
    })

    console.log(personvernText);
    return (
        <Card className={classes.container}>
            {personvernMapped}
        </Card>
    );
}

export default PersonvernPage;