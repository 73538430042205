import classes from './SearchSortBar.module.css';
import {Card} from "@material-ui/core";

const SearchSortBar = (props) => {

    const onChangeFilter = (event) => {
       props.onChangeFilter(event.target.value);
    }

    const onChangeSort = (event) => {
        props.onChangeSort(event.target.value);
    }

    return (
        <Card className={classes.bar}>
            <div className={classes['wrapper-search']}>
                <input className={classes.input} type="text" placeholder="SØK PÅ NAVN I LISTEN" id="search" onChange={onChangeFilter}/>
            </div>
            {<div className={classes['wrapper-sort']}>
                <select id="sorter" className={classes.input} onChange={onChangeSort}>
                    <option value="nf">Sorter data: Nyeste -> Eldste</option>
                    <option value="ef">Sorter data: Eldste -> Nyeste</option>
                </select>
            </div>}
        </Card>
    );
}

export default SearchSortBar;