import {GenerateTitleCards} from "../components/layout/handlers/GlobalHandlers";
import {Fragment} from "react";
import ProductDetail from "../components/UI/products/ProductDetail";
import {useSelector} from "react-redux";

const NettbutikkPage = () => {

    /* Get Products and Static Content */
    const content = useSelector(state => state.content.staticContent);
    const products = useSelector(state => state.content.globalProducts);

    const productList = products.map((items) => (
        <ProductDetail key={`productdetail_${items['id_produkt']}`} item={items}/>
    ));

    return (
        <Fragment>
            <GenerateTitleCards data={content} group='cover' where='nettbutikk'/>
            <div className='body-card-container'>
                {productList}
            </div>
        </Fragment>
    );
};

export default NettbutikkPage;