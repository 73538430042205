/* Import Hooks and services */
import {Fragment, useCallback, useEffect, useState} from "react";
import {Switch, Route} from 'react-router-dom';

/* Import Pages */
import HomePage from "./pages/HomePage";
import ErrorModal from "./components/UI/modals/ErrorModal";
import ForbindelsePage from "./pages/forbindelse/ForbindelsePage";
import LaderePage from "./pages/LaderePage";
import NotFoundPage from "./pages/NotFoundPage";
import InstallPage from "./pages/InstallPage";
import NettbutikkPage from "./pages/NettbutikkPage";
import LokasjonerPage from "./pages/LokasjonerPage";
import KurvPage from "./pages/KurvPage";
import ProductPage from "./components/UI/products/ProductPage";
import Layout from "./components/layout/Layout";
import KategoriPage from "./components/UI/products/KategoriPage";
import ViewRequestPage from "./pages/admin/meldinger/ViewRequestPage";
import PanelPage from "./pages/admin/PanelPage";
import ViewOrderPage from "./pages/admin/orders/ViewOrdrePage";
import PersonvernPage from "./pages/PersonvernPage";

/* Import Modules */
import LoadingModule from "./components/UI/modals/LoadingModule";
import ScrollToTop from "./components/layout/interactive/ScrollToTop";
import {runEaster} from './components/layout/interactive/ConsoleEaster';

/* Import Actions and Disptach */
import {contentAction} from "./store/content-slice";
import {useDispatch, useSelector} from "react-redux";

function App() {
    /* Init Error Handler */
    const [initError, setInitError] = useState(null);

    /* Init Loading Handler -
    * Since we are always going to start loading data when the page run first time and not agen after
    * we can set this to true from the beginning. And skip a update state. */
    const [isLoading, setIsLoading] = useState(true);

    /* Init Redux Store, setup dispatch and Actions are imported in the top */
    const disptach = useDispatch();

    /* Get API URL */
    const API_URL = useSelector(state => state.content.apiURL);

    const isLoggedIn = useSelector(state => state.auth.isLoggedIn);

    /* Error Handler hide */
    const hideErrorHandler = () => {
        window.location.reload();
    }

    /* Update width when size changes and toogle big screen */
    useEffect(() => {
        const handleResize = () => {
            /* Is it bigger then 1200, set isBigTo True if it is */
            disptach(contentAction.updateIsBig(window.innerWidth >= 1200));
        };

        /* Add windows listner and point to handleResize*/
        window.addEventListener("resize", handleResize);

        /* Remove event listner on cleanup */
        return () => window.removeEventListener("resize", handleResize);

        /* Empty dependndcy so it only run once */
    }, [disptach]);

    /* Menu Fetcher */
    const fetchInitData = useCallback(async () => {
        /* Reset Error - Here we would normaly set this to null, but since this is the
        * first fetch function to run and we start with it as null we dont need to set it
        * once more. This is because we dont plan on running it agen. */

        const getRequest = async (url, what) => {
            /* Get Data from API */
            try {
                const response = await fetch(url);
                if (!response.ok) {
                    throw new Error(what + response.status.toString())
                }

                /* Load JSON */
                const data = await response.json();

                /* Check status */
                const statusData = data ? data.status : null;
                return statusData ? data.content : [];

            } catch (error) {
                setInitError(error + ". Klarte ikke og koble til databasen. Dette kan skyldes vedlikehold eller dårlig forbindelse. Vent litt og forsøk igjen.");
                return [];
            }
        }

        const headerMenu = await getRequest(`${API_URL}/api/menu`, 'H');
        const footerMenu = await getRequest(`${API_URL}/api/menu/phone`, 'F');
        const chargerPosition = await getRequest(`${API_URL}/api/content/pos`, 'P');
        const staticContent = await getRequest(`${API_URL}/api/content`, 'C');
        const contactForm = await getRequest(`${API_URL}/api/content/contact-form`, 'CF');
        const globalInfo = await getRequest(`${API_URL}/api/content/info`, 'I');
        const globalText = await getRequest(`${API_URL}/api/content/text-no`, 'GT');
        const globalProducts = await getRequest(`${API_URL}/api/products`, 'PR');
        const globalProductsInstall = await getRequest(`${API_URL}/api/products/install`, 'PRI');
        const cartForm = await getRequest(`${API_URL}/api/content/cart-form`, 'CAF');

        /* Setting Global Content */
        disptach(contentAction.updateHeaderMenu(headerMenu));
        disptach(contentAction.updateFooterMenu(footerMenu));
        disptach(contentAction.updateChargerPosition(chargerPosition));
        disptach(contentAction.updateStaticContent(staticContent));
        disptach(contentAction.updateContactForm(contactForm));
        disptach(contentAction.updateGlobalInfo(globalInfo));
        disptach(contentAction.updateGlobalText(globalText));
        disptach(contentAction.updateGlobalProducts(globalProducts));
        disptach(contentAction.updateGlobalProductsInstall(globalProductsInstall));
        disptach(contentAction.updateCartForm(cartForm));

        /* Data loading done */
        setIsLoading(false);
    }, [disptach, API_URL]);

    /* Run Load Data Once on startup, using callback to prevent inifiti loop. */
    useEffect(() => {
        runEaster();
        fetchInitData();
    }, [fetchInitData]);

    return (
        <Fragment>
            {isLoading && <LoadingModule/>}
            {initError && !isLoading && <ErrorModal onClose={hideErrorHandler} message={initError}/>}
            {!isLoading && (
                <Layout>
                    {/* Only one rout will be active at the same time */}
                    <ScrollToTop/>
                    <Switch>
                        <Route path="/" exact>
                            <HomePage/>
                        </Route>

                        <Route path="/nettbutikk" exact>
                            <NettbutikkPage/>
                        </Route>

                        <Route path="/nettbutikk/kategori/:kategori" exact>
                            <KategoriPage/>
                        </Route>

                        <Route path="/nettbutikk/:productid">
                            <ProductPage/>
                        </Route>

                        <Route path="/stasjoner">
                            <LokasjonerPage/>
                        </Route>

                        <Route path="/montor">
                            <InstallPage/>
                        </Route>

                        <Route path="/ladere">
                            <LaderePage/>
                        </Route>

                        <Route path="/forbindelse">
                            <ForbindelsePage/>
                        </Route>

                        <Route path="/kurv">
                            <KurvPage/>
                        </Route>

                        <Route path="/panel" exact>
                            <PanelPage/>
                        </Route>

                        {isLoggedIn &&
                        <Route path="/panel/order/:token" exact>
                            <ViewOrderPage/>
                        </Route>}

                        {isLoggedIn &&
                        <Route path="/panel/request/:token" exact>
                            <ViewRequestPage/>
                        </Route>}

                        <Route path="/personvern" exact>
                            <PersonvernPage />
                        </Route>}

                        <Route path='*'>
                            <NotFoundPage/>
                        </Route>
                    </Switch>
                </Layout>
            )}
        </Fragment>
    );
};

export default App;
