import classes from './CartSummary.module.css';
import SlideButton from "../button/SlideButton";
import IconHandler from "../../layout/handlers/IconHandler";
import {useSelector} from "react-redux";

const CartSummary = (props) => {

    /* Global Text */
    const globaltext = useSelector(state => state.content.globalText);

    /* Props Amount */
    const moms = (props.totalAmount * 0.25).toFixed(2);
    const utenMoms = (props.totalAmount - moms).toFixed(2);

    const totalSpan = (
        <div className={classes['summary-container__summary']}>
            <div className={classes['summary-container__row_left']}>{globaltext['cart_summary_total_moms']}</div>
            <div className={classes['summary-container__row_right']}>{props.totalAmount} NOK</div>
        </div>
    );

    return (
        <div className={classes['summary-container']}>
            <div>
                <div className={classes['summary-container__row']}>
                    <h2>{globaltext['cart_summary_title']}</h2>
                </div>
                <div className={classes['summary-container__row']}>
                    <div className={classes['summary-container__row_left']}>{globaltext['cart_summary_total']}</div>
                    <div className={classes['summary-container__row_right']}>{utenMoms} KR</div>
                </div>
                <div className={classes['summary-container__row']}>
                    <div className={classes['summary-container__row_left']}>{globaltext['cart_summary_moms']}</div>
                    <div className={classes['summary-container__row_right']}>{moms} KR</div>
                </div>

                {props.isReady && totalSpan}
            </div>

            {/* Show next step if not ready */}
            {!props.isReady && <SlideButton textOne={globaltext['cart_step_one_text_one']} textOneHint={`${props.totalAmount} KR (ink. MVA)`} textTwo={globaltext['cart_step_one_text_two']} icon="deliver" onClick={props.onClick}/>}

            {/* Show chekc out if ready */}
            {props.isReady && <SlideButton textOne={globaltext['cart_step_two_text_one']} textOneHint={<IconHandler icon="deliver" />} textTwo={globaltext['cart_step_two_text_two']} icon="confirm" onClick={props.onClick}/>}
            <div>
                {props.error && <p className={classes.error}>Du har feil i skjemaet, vennligst fyll in følgende: <br /><br />{props.error}</p>}
                <p className={classes['info-viktig']}>{globaltext['cart_info']}</p>
            </div>
        </div>

    );
};

export default CartSummary;

