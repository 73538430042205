/* Configuration of stores for project */
import { configureStore } from "@reduxjs/toolkit";

/* Import of slices */
import cartSlice from './cart-slice';
import contentSlice from './content-slice';
import authSlice from './auth-slice';

/* Setting up store for reducer */
const store = configureStore({
    reducer: {
        cart: cartSlice,
        content: contentSlice,
        auth: authSlice,
    }
});

export default store;
