import Badge from '@material-ui/core/Badge';
import CartIcon from '@material-ui/icons/ShoppingCart';
import {IconButton} from "@material-ui/core";
import {withStyles} from "@material-ui/core";
import {useSelector} from "react-redux";
import {useHistory} from "react-router-dom";

const HeaderCartButton = (props) => {
    /* Import Navigation */
    const history = useHistory();

    /* Get total amount from state */
    const itemCount = useSelector(state => state.cart.totalQuantity);

    const StyledBadge = withStyles((theme) => ({
        badge: {
            right: 0,
            top: 0,
            border: `2px solid #495f3a`,
            background: '#495f3a',
            padding: '1 0px',
            color: '#ffffff'
        },
    }))(Badge);

    const cartColor = (!props.color ? '#252525' : props.color);

    const onCartClick = () => {
        history.push("/kurv");
    }

    return (
        <IconButton aria-label="cart" color="default" onClick={onCartClick}>
            <StyledBadge badgeContent={itemCount}>
                <CartIcon style={{ color: cartColor }} />
            </StyledBadge>
        </IconButton>
    );
}

export default HeaderCartButton;