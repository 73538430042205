import classes from './ProductDetail.module.css';
import {Card} from "@material-ui/core";
import {useDispatch} from "react-redux";
import {cartAction} from "../../../store/cart-slice";
import SlideButton from "../button/SlideButton";
import {Link, useHistory} from "react-router-dom";

const ProductDetail = (props) => {
    /* Set tekst for button based on monter or store */
    const buyTekst = props.monter ?  "Få et tilbud!" : "Kjøp nå!";
    const buyIcon = props.monter ? "phone" : "store";
    const buyCart = props.monter ? "Ring oss idag" : "Legg i kurven";
    const buyNOK = props.monter ? "Fra " : "";

    /* Import disptach for addin to Redux */
    const dispatch = useDispatch();

    /* History */
    const history = useHistory();

    const onAddToCartHandler = (newItem) => {
        /* If install item then something else add to cart */
        if(props.monter) {
            history.push("/forbindelse")
        } else {
            /* Adding Item to Cart */
            dispatch(cartAction.addItem({
                id: newItem.id,
                price: newItem.price,
            }));
        }
    }

    const htmlList = props.item.detail_list.split("|").map((item) => {
        const itemSplit = item.split(":");
        return (<li key={item}><strong>{itemSplit[0]}: </strong>{itemSplit[1]}</li>);
    });

    return (
        <Card className={classes.container}>
            <div className={classes['container-row']}>
                <div className={classes['product-details']}>
                    <div className={classes['product-details__wrapper']}>
                        {!props.monter && <Link to={`/nettbutikk/${props.item['id_produkt']}`}><h1>{props.item.navn}</h1></Link>}
                        {props.monter && <h1>{props.item.navn}</h1>}
                        {!props.monter && <span className={classes.category}>Kategori: <Link to={`/nettbutikk/kategori/${props.item['kategori']}`} className={classes.link}>{props.item['kategori']}</Link></span>}
                        {props.monter && <span className={classes.category}>Kategori: {props.item['kategori']}</span>}
                        <p>{props.item.beskrivelse_short}</p>

                        <div className={classes.control}>
                            <SlideButton textOne={buyTekst}
                                         textTwo={buyCart}
                                         textOneHint={`${buyNOK}${props.item.pris_ink_mva} NOK`}
                                         icon={buyIcon}
                                         onClick={onAddToCartHandler.bind(null, {id: props.item['id_produkt'], price: props.item.pris_ink_mva})}
                            />
                        </div>
                    </div>
                </div>

                <div className={classes.product}>
                    <div className={classes['product-image']}>
                        <img className={classes.img}
                             src={props.item.media_link}
                             alt={props.item.media_alt}/>

                        <div className={classes.info}>
                            <h2>Detaljer om produktet</h2>
                            <ul>
                                {htmlList}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </Card>
    );
}

export default ProductDetail;
