import classes from './MapCurrentStations.module.css'
import {Box, Card} from "@material-ui/core";
import {MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'
import {Info, InfoSubtitle, InfoTitle} from "@mui-treasury/components/info";
import {useSelector} from "react-redux";

const MapCurrentStations = () => {

    /* Get pos from store */
    const pos = useSelector(state => state.content.chargerPosition);

    const positionsRendered = pos.map((pos) => (
        <Marker key={`pos_${pos.id}`} position={[pos.lat, pos.long]}><Popup>{pos.info}</Popup></Marker>
    ));

    const mapTitle =
    <Box py={3} px={2} className={classes.content}>
        <Info>
            <InfoSubtitle className={classes.subtitle}>Her har vi betalingsladere idag!</InfoSubtitle>
            <InfoTitle className={classes.title}>Ladetnettverket AS sine stasjoner</InfoTitle>
        </Info>
    </Box>;

    const MapControl = () => {
        return (
            <div className="leaflet-bottom leaflet-left">
                <div className="leaflet-control leaflet-bar">{mapTitle}</div>
            </div>
        );
    }

    return (
        <Card className={classes.card}>
            <MapContainer
                center={[59.85360342684254, 10.545925688325429]}
                zoom={11}
                scrollWheelZoom={false}
                style={{height: "500px"}}>
                    <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"/>
                    {positionsRendered}
                    <MapControl />
            </MapContainer>
        </Card>
    );
};

export default MapCurrentStations;