/* Custom Cart Slice for handeling auth in panel */
import {createSlice} from "@reduxjs/toolkit";

/* Calculate Expiration */
const calculateRemainingTime = (expirationTime) => {
    const currentTime = new Date().getTime();
    const adjExpirationTime = new Date(expirationTime).getTime();

    const remainingDuration = adjExpirationTime - currentTime;

    return remainingDuration;
}

/* Get Localstorage */
const getSession = () => {
    const currentSession = JSON.parse(localStorage.getItem("session"));
    if(currentSession) {
        if(calculateRemainingTime(currentSession.session_expire) > 0) {
            return {
                isLoggedIn: true,
                ...currentSession,
            };
        } else {
            return null;
        }
    } else {
        return null;
    }
}

const localappdata = getSession();

const authSlice = createSlice({
    name: 'auth',

    initialState: {
        isLoggedIn: localappdata ? localappdata.isLoggedIn : false,
        user: localappdata ? localappdata.user : "",
        session: localappdata ? localappdata.session : "",
        session_expire: localappdata ? localappdata.session_expire : "",
        last_login: localappdata ? localappdata.last_login : "",
        viewOrder: true,
    },

    reducers: {

        userLogin(state, action) {
            state.user = action.payload.user;
            state.session = action.payload.session;
            state.session_expire = action.payload.session_expire;
            state.last_login = action.payload.last_login;
            state.isLoggedIn = true;

            /* Saving token also to local storage */
            localStorage.setItem("session", JSON.stringify({user: action.payload.user, session: action.payload.session, session_expire: action.payload.session_expire, last_login: action.payload.last_login}));
        },

        setLoggedIn(state, action) {
            state.isLoggedIn = action.payload;
        },

        setViewOrder(state, action) {
            state.viewOrder = action.payload;
        },

        setUser(state, action) {
            state.user = action.payload;
        },

        setSession(state, action) {
            state.session = action.payload;
        },

        setSessionExpire(state, action) {
            state.session_expire = action.payload;
        },

        setLastLogin(state, action) {
            state.last_login = action.payload;
        },

        logout(state) {
            state.isLoggedIn = false;
            state.user = "";
            state.session = "";
            state.session_expire = "";
            state.last_login = "";
        }
    }
});

/* Export Actions */
export const authAction = authSlice.actions;

/* Export Reducers */
export default authSlice.reducer;