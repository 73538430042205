import classes from './CustomCard.module.css';
import {Button, Card, CardActionArea, CardActions, CardContent, CardMedia, Typography} from "@material-ui/core";

const CustomCard = (props) => {
    return (
        <Card className={classes['custom-card']}>
            <CardActionArea>
                <CardMedia
                    component="img"
                    alt={props.altImage}
                    heigth="140"
                    image={props.image}
                    title={props.title}
                />

                <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                        {props.title}
                    </Typography>
                    <Typography variant="body2" color='textSecondary' component='p'>
                        {props.description}
                    </Typography>
                </CardContent>
            </CardActionArea>
            <CardActions>
                {props.B1Text && <Button size="small" onClick={props.B1OnClick} color="primary" className={classes.btn}>{props.B1Text}</Button>}
                {props.B2Text && <Button size="small" onClick={props.B2OnClick} color="primary" className={classes.btn}>{props.B2Text}</Button>}
            </CardActions>
        </Card>
    );
};

export default CustomCard;