/* Easter Eggs For Console */

const asciiBattery = (`
████████████████████████████████████
██                                ██
██  ████  ████  ████  ████  ████  ████
██  ████  ████  ████  ████  ████  ████
██  ████  ████  ████  ████  ████  ████
██  ████  ████  ████  ████  ████  ████
██  ████  ████  ████  ████  ████  ████
██                                ██
████████████████████████████████████ 

Ladenettverket AS sin nettside laget av Marcus Knoph © 2021

`);

export const runEaster = () => {
    console.log(asciiBattery);
};

